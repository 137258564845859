import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22')
];

export const server_loads = [0];

export const dictionary = {
		"/": [2],
		"/(student)/about": [3],
		"/(teacher)/campaigns": [10],
		"/(teacher)/complaints": [11],
		"/(teacher)/groups": [12],
		"/(teacher)/groups/[id=slug]": [13],
		"/(teacher)/groups/[id=slug]/[studentId=slug]": [14],
		"/(student)/home": [4],
		"/(teacher)/incentive": [15],
		"/(teacher)/login": [16],
		"/(student)/notifications": [5],
		"/(student)/practice": [6],
		"/(student)/pt/[id]": [7],
		"/(teacher)/sentences": [17],
		"/(teacher)/settings": [18],
		"/(student)/signin": [8],
		"/(teacher)/students": [19],
		"/(teacher)/tasks": [20],
		"/(teacher)/tasks/[id=slug]": [21],
		"/(student)/t/[id]": [9],
		"/(teacher)/users": [22]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';