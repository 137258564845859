import { handleErrorWithSentry } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';

const isProd = import.meta.env.MODE === 'production';

if (isProd) {
	Sentry.init({
		dsn: 'https://60a2d4205a56725f69d9caedbc149c93@o1410609.ingest.sentry.io/4505641695903744'
	});
}

export const handleError = isProd ? handleErrorWithSentry() : undefined;
